import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'
import CONSTANT from '@/config/constant'
import APIWHITES from '@/config/api-whites'
import errorCode from '@/config/error'
import store from '../store/index'

function apiAxios(method, url, params, success, failure) {
  // console.log(method, url, params)
  let headers = {}
  var matchedItems = APIWHITES.filter(item => new RegExp(url, 'i').test(item))
  // console.log(matchedItems)
  if (matchedItems.length === 0) {
    // console.log(store.state.common.userInfo)
    headers.token = store.state.common.userInfo.token
  } else if (url.indexOf('/email/send') > -1 || url.indexOf('/email/setPassword') > -1) {
    headers.token = params.token
  }
  let data = method === 'POST' || method === 'PUT' ? params : null
  axios({
      headers: headers,
      method: method,
      url: url,
      data: data,
      params: method === 'GET' || method === 'DELETE' ? params : null,
      withCredentials: false
    }).then(function(res) {
      // console.log(res)
      if (res.status === 200) {
        // console.log(res.data)
        if (res.data.code === 200) {
          if (success) success(res.data.data)
        } else if(res.data.code === 400 || res.data.code === 401 || res.data.code === 403){
          Message({
            type: 'warning',
            message: res.data.data
          })
          router.push('/login')
        } else {
          Message({
            type: 'warning',
            message: res.data.data
          })
          if (failure) failure(res.data)
        }
      } else if (res.status === 400 || res.status === 401 || res.status === 403) {
        Message({
          type: 'warning',
          message: res.data.data
        })
        router.push('/login')
      } else {
        if (failure) failure(res.data)
      }
    }).catch(function(err) {
      console.log(err)
      failure(err)
      Message({
        type: 'error',
        message: errorCode[err.status] || errorCode['default']
      })
    })
}

export default {
  get: function(url, params, success, failure) {
    return apiAxios('GET', url, params, success, failure)
  },
  post: function(url, params, success, failure) {
    return apiAxios('POST', url, params, success, failure)
  },
  put: function(url, params, success, failure) {
    return apiAxios('PUT', url, params, success, failure)
  },
  delete: function(url, params, success, failure) {
    return apiAxios('DELETE', url, params, success, failure)
  }
}