export default {
  '400': 'Parameter abnormality, please check',
  '401': 'No permission',
  '403': 'Server denied access',
  '404': 'Request address error',
  '405': 'Request method not allowed',
  '408': 'request timeout',
  '409': 'Request Conflict',
  '411': 'Content-Length undefined',
  '413': 'The server refused to process the request',
  '414': 'The requested URI is too long',
  '415': 'Request format error',
  '423': 'Request resource locked',
  '425': 'This request is at risk',
  '451': 'This request is illegal',
  '500': 'Server error',
  '501': 'Unable to recognize the request',
  '502': 'Bad Gateway',
  '503': 'Temporary maintenance or overload of servers',
  '504': 'Gateway timeout',
  '505': 'The HTTP version is not supported',
  '506': 'Server configuration error',
  '509': 'The server has reached the bandwidth limit',
  '600': 'Unreturned Echo Response Head',
  'default': 'The server has deviated. Please try again later'
}



